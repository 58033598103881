<template>

<!-- 机构端 校园筛查 筛查前设置 学校管理页面  -->
  <div class="page">
    <div class="header">
      <div class="header-input">
      </div>
      <div class="header-search">
        <el-input
          maxlength="11"
          placeholder="请输入学校名称"
          size="mini"
          class="search"
          prefix-icon="el-icon-search"
          v-model="searchValue"
        ></el-input>
      </div>
      <div class="header-button">
        <div class="header-button-action">
          <el-button type="primary" size="mini" round @click="search">搜索</el-button>
          <el-button type="primary" size="mini" round @click="reset">重置</el-button>
        </div>
        <div class="header-button-add">
          <el-button type="primary" size="mini" round icon="el-icon-plus" @click="goTo('/detection/schoolAdd')">新增学校</el-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="table-main">
        <el-table :data="tablePageData" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName"  style="width: 100%;">
          <el-table-column type="selection" >
          </el-table-column>
          <el-table-column prop="schoolName" label="学校名称" :formatter="formNull" width="280">
          </el-table-column>
          <el-table-column prop="schoolType" label="学校类型" :formatter="formatterType" width="140" align="center">
          </el-table-column>
          <el-table-column prop="schoolNature" label="学校性质" :formatter="formatterNature" width="160" align="center">
          </el-table-column>
          <el-table-column label="所在地" :formatter="formatterAddress"  align="center">
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="goTo('/detection/schoolEdit',scope.row)">修改</el-button>
                <el-button size="small" type="text" style="color: #FF0000;" slot="reference" @click="delSchool(0,scope.$index,scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{'marginTop': '52px'}">
              <img src="@/assets/detection/image/404.png" alt="" width="333" />
            </div>
          </div>
        </el-table>
        <div class="table-page">
          <el-button
            :type="batchNum?'primary':'disabled'"
            :disabled="batchNum?false:true"
            size="mini"
            class="delete-button" round
            @click="delSchool(1)"
          >批量删除({{batchNum}})</el-button>
          <pagination :current-page="startPage" :total="totalPage"  @currentChange="handleCurrentChange" @sizeChange="sizeChange"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import schoolService from '@/globals/service/detection/school.js'

export default {
  data () {
    return {
      searchValue: '',
      total: 0,
      totalPage: 0,
      startPage: 1, // 当前页码
      pageSize: 8,
      tableData: [],
      tablePageData: [],
      multipleSelection: [],
      batchNum: 0,
      searchStatue: false
    }
  },
  mounted () {
    this.getListData()
  },
  activated () {},
  methods: {
    sizeChange (val) {
      this.pageSize = val
      this.getListData()
    },
    getListData () {
      schoolService.list({ startPage: this.startPage, pageSize: this.pageSize }).then(res => {
        if (res.data.totalPage) {
          this.total = res.data.totalPage * this.pageSize
          this.totalPage = res.data.totalPage
          this.tablePageData = res.data.schoolList
        } else {
          this.total = 0
          this.tablePageData = []
        }
      })
    },
    search () {
      const searchValue = this.searchValue
      if (searchValue === '') {
        this.$message.error('请输入搜索内容')
        return false
      }
      schoolService.search({ name: searchValue }).then(res => {
        this.searchStatue = true
        this.total = res.data.length || 0
        if (this.total) {
          this.tableData = res.data
        } else {
          this.tableData = []
        }
        this.dataPages()
      })
    },
    dataPages () {
      const tableData = this.tableData
      const startPage = (this.startPage - 1) * this.pageSize
      if (this.total) {
        this.tablePageData = tableData.slice(startPage, startPage + this.pageSize)
      } else {
        this.total = 0
        this.tablePageData = []
      }
    },
    reset () {
      this.searchStatue = false
      this.searchValue = ''
      this.startPage = 1
      this.getListData()
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    formNull (row, column, cellValue) {
      if (cellValue === '') {
        return '-'
      } else {
        return cellValue
      }
    },
    formatterType (row, column, cellValue) {
      if (cellValue === '') {
        return '-'
      } else {
        const data = ['幼儿园', '小学', '初中', '高中', '大学', '幼小', '初幼', '幼高', '小初', '小高', '初高']
        return data[cellValue]
      }
    },
    formatterNature (row, column, cellValue) {
      if (cellValue === 1) {
        return '民办'
      } else if (cellValue === 0) {
        return '公办'
      } else {
        return '-'
      }
    },
    formatterAddress (row) {
      return row.provinceName + ' ' + row.cityName + ' ' + row.areaName + ' ' + row.address
    },
    delSchool (type, index, id) {
      let schoolIdList = []
      if (type === 1) {
        this.$confirm('请确认要全部删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const multipleSelection = this.multipleSelection
          multipleSelection.forEach(item => {
            schoolIdList.push(item.id)
          })
          schoolService.del({ schoolIdList: schoolIdList }).then(res => {
            this.$message({ type: 'success', message: '删除成功!' })
            this.tablePageData.splice(index, 1)
          })
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消删除' })
          return false
        })
      } else {
        this.$confirm('此操作将永久删除当前所选, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          schoolIdList = [id]
          schoolService.del({ schoolIdList: schoolIdList }).then(res => {
            this.$message({ type: 'success', message: '删除成功!' })
            this.getListData()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    handleCurrentChange (val) {
      this.startPage = val
      if (this.searchStatue) {
        this.dataPages()
      } else {
        this.getListData()
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleSelectionChange (rows) {
      this.multipleSelection = rows
      this.batchNum = rows.length
    }
  }

}
</script>
<style lang="scss" scoped>
.page{
  padding: 10px 20px 0;
  background-color: #fff;
  width: 100%;
  min-height: 100%;
}
  .page .header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0 16px;
  }
  .page .header .el-input,.page .header .el-button{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .header-search .search>>>.el-input__inner{
    width: 178px;
    border: 1px solid #4DAF5A;
    border-radius: 18px;
    margin-right: 16px;
  }
  .header-button{
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 30px;
  }
  .table-page{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }
</style>
